.site-head {
  height: 4.4em; }
  .site-head .right-elems .wrap {
    padding: .5rem;
    margin-left: 1.8rem;
    cursor: pointer;
    position: relative; }
    .site-head .right-elems .wrap .dropdown-item:active {
      background: #f5f5f5;
      color: #212121; }
  .site-head .right-elems .profile {
    padding: 0; }
    .site-head .right-elems .profile img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      border: 2px solid #c8e6c9; }
  .site-head .right-elems .notify .badge {
    font-size: 65%;
    position: absolute;
    top: -4px;
    right: -4px; }
  .site-head .site-search {
    position: relative; }
    .site-head .site-search input {
      font-size: 14px;
      border: none;
      border-radius: 1px;
      padding-left: 3.5rem;
      transition: .2s ease-in;
      padding-top: .9rem;
      padding-bottom: .9rem; }
    .site-head .site-search > svg {
      position: absolute;
      top: .8rem;
      left: 1rem; }
