.scrollarea-content {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative; }
  .scrollarea-content:focus {
    outline: 0; }

.scrollarea {
  position: relative;
  overflow: hidden; }
  .scrollarea .scrollbar-container {
    position: absolute;
    background: none;
    opacity: .1;
    z-index: 9999;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s; }
    .scrollarea .scrollbar-container.horizontal {
      width: 100%;
      height: 10px;
      left: 0;
      bottom: 0; }
      .scrollarea .scrollbar-container.horizontal .scrollbar {
        width: 20px;
        height: 8px;
        background: black;
        margin-top: 1px; }
    .scrollarea .scrollbar-container.vertical {
      width: 10px;
      height: 100%;
      right: 0;
      top: 0; }
      .scrollarea .scrollbar-container.vertical .scrollbar {
        width: 8px;
        height: 20px;
        background: black;
        margin-left: 1px; }
    .scrollarea .scrollbar-container:hover {
      background: gray;
      opacity: .6 !important; }
    .scrollarea .scrollbar-container.active {
      background: gray;
      opacity: .6 !important; }
  .scrollarea:hover .scrollbar-container {
    opacity: .3; }
