.dropzone {
  border: 2px dashed #0091ea;
  border-radius: 5px;
  background: white;
  padding: 54px 54px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto; }
  .dropzone .dropzone-message {
    text-align: center; }
