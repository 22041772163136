

.value-horizontal {
  display: flex;
  // flex: 0 0 0%;
  justify-content: center;
  align-items: center;
  height: 3rem;
  font-size: 2.5vmin ;
  margin-left: 1vmin;
  margin-right: 1vmin;
}

.value-straight {
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-size: 2.5vmin ;
  margin-top: 1vmin;
  margin-bottom: 1vmin;
}
.arrow-horizontal {
  display: flex;
  flex: 0 0 45%;
}

.arrow-horizontal-2 { 
  display: flex;
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 0%;
}



.arrow-straight {
  display: flex;
  flex: 0 0 45%;
  flex-direction: column;
}

.arrow-straight-2 {
  display: flex;
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 0%;
  flex-direction: column;
}


.line-horizontal {
  width: 100%;
  margin-top: 1.5rem;
  background: black;
  height: 5px;
}

.line-straight {
  width: 5px;
  margin-left: 1rem;
  background: black;
  height: 100%;
}

.point-right {
  margin-top: 1.1rem;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 20px solid black;
}

.point-left {
  margin-top: 1.1rem;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 20px solid black;
}

.point-up {
  width: 0;
  height: 0;
  margin-left: 0.61rem;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 20px solid black;
}

.point-down {
  width: 0;
  height: 0;
  margin-left: 0.61rem;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 20px solid black;
}

.rate-straight {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.rate-horizontal {
  display: flex;
  margin-left: 3.5rem;
}

.rate-container {
  width: 100%;
  display: flex;
}

.container-vertical{
  width: 4rem;
  height: 100%;
  display: flex;
}

.container-horizontal{
  width: 100%;
  display: flex;
  flex-direction: column;
}


