.comment-box {
  margin: 10px 0; }

.skip-check-box {
  position: relative !important;
  margin-left: 0 !important;
  margin-right: 0.25rem; }

.scrollable-dropdown-menu {
  overflow-y: scroll;
  max-height: 200px;
  max-width: 266px; }
